import { React, createContext, useContext, useState, useEffect } from 'react'

const defaultState = {
  lang: 'pl',
  setLang: () => {},
  toggleLang: () => {},
}

const LangContext = createContext(defaultState)

export const LangProvider = ({ children }) => {
  const [lang, setLangState] = useState('pl')

  const setLang = (langToSet) => {
    localStorage.setItem('lang', JSON.stringify(langToSet))
    setLangState(langToSet)
  }

  const setLangNoLocalSave = (langToSet) => {
    setLangState(langToSet)
  }

  const toggleLang = () => {
    const newLang = lang === 'pl' ? 'en' : 'pl'
    setLangState(newLang)
    localStorage.setItem('lang', JSON.stringify(newLang))
  }

  useEffect(() => {
    // if (typeof window !== 'undefined') {
    //   const navLang = window.navigator.userLanguage || window.navigator.language
    //   // console.log(navLang)
    //   if (navLang?.slice(0, 2) === 'pl') setLangNoLocalSave('pl')
    //   else if (navLang?.slice(0, 2) === 'en') setLangNoLocalSave('en')
    // }
    const lsLang = JSON.parse(localStorage.getItem('lang'))
    if (lsLang === 'pl' || lsLang === 'en'  || lsLang === 'ua') setLangNoLocalSave(lsLang)
  }, [])

  return (
    <LangContext.Provider value={{ lang, toggleLang, setLang }}>
      {children}
    </LangContext.Provider>
  )
}

export default LangContext

export const useLangContext = () => useContext(LangContext)